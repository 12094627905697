<template>
	<Layout>
		<v-toolbar class="grey lighten-3" max-height="70">
			<v-toolbar-title>
				<v-btn text class="grey--text" :to="{name: 'TmodelView'}">
					<v-icon class="ml-2">mdi-chevron-double-left</v-icon> GO BACK
				</v-btn>
			</v-toolbar-title>
		</v-toolbar>
		<v-layout fill-height justify-center align-center v-if="loading">
			<v-progress-circular
				:size="200"
				:width="25"
				indeterminate
				color="primary"
			/>
		</v-layout>
		<v-container class="grey" fluid v-else>
			<v-row  justify="center" align-content="center" align="center">
				<v-col cols="12">

					<v-card dark class="mx-auto">
						<v-card-title class="mb-3 secondary title font-weight-regular justify-space-between">
							<span class="white--text">RUN TEST MODEL</span>
						</v-card-title>

						<v-card-text>
							<h3>Project: {{ tmodel.project.title }}</h3>
							<h2 class="mb-2">{{ tmodel.titleForm }}</h2>
							<h3>{{ tmodel.subtitleForm }}</h3>
							<v-divider class="my-3"/>
							<strong>Note</strong>: <em>Do not use these results for production!, this model is not fully trained, we are showing a very simple prediction. We will build and train a better model for your particular use case once you engage with us</em>.
							<v-spacer class="my-4" />
								<v-row justify="center">
									<v-col cols="12" sm="4">
										<v-card class="mx-auto mt-3 elevation-5 primary darken-3" shaped>
											<v-card-title class="mb-3 primary darken-1">
												Fill data to test
											</v-card-title>
											<v-card-text>
												<p class="grey--text">Complete all data fields:</p>
												<v-form ref="form">
													<div v-for="item in tmodel.variables" :key="item._id">
														<v-text-field
															v-model="item.data"
															filled
															:rules="[rules.required]"
															:label="item.label"
															required
															@change="setValue(item)"
															dense
														/>
													</div>
													<v-text-field
														dense
														outlined
														v-model="targetVariable"
														:label="tmodel.targetVarLabel"
														persistent-hint
														:hint="tmodel.targetVarHint"
														required
														@change="toLowerCase"
													/>
												</v-form>
											</v-card-text>
											<v-card-actions >
												<v-btn  text outlined class="mr-2 grey--text"  @click="resetForm"><v-icon class="mr-2">mdi-recycle-variant</v-icon> RESET </v-btn>
												<v-spacer/>
												<v-btn v-if="!calculated" class="primary" dark depressed @click="calculate">CALCULATE <v-icon>mdi-chevron-double-right</v-icon></v-btn>
											</v-card-actions>
										</v-card class="mx-auto mt-3">
									</v-col>
									<v-col cols="12" sm="8" v-if="calculated">
										<v-card class="elevation-5 primary darken-3" shaped>
											<v-card-title class="mb-3 primary darken-1">
												RESULTS
											</v-card-title>
											<v-card-text v-if="processing == true">
												<v-layout fill-height justify-center align-center >
													<v-progress-circular
														:size="100"
														:width="15"
														indeterminate
														color="secondary"
													/>
												</v-layout>
											</v-card-text>
											<v-card-text v-if="calculated == true && processing == false">

												<v-alert
														class="my-4"
														prominent
														type="error"
														v-if="targetVariable != result[0].displayName"
													>
														<strong>"{{targetVariable}}</strong> given is likely to be incorrect!"<br/>
														We estimate the correct value to be <strong>{{result[0].displayName}}</strong>: <strong>{{result[0].score}}%</strong>.
												</v-alert>
												<v-alert type="success" v-else>
													This value is correct <strong>{{targetVariable}}</strong>: <strong>{{result[0].score}}</strong>%.
												</v-alert>
												<section>
													<h3>Confidence score (%)</h3>
													<v-list-item two-line v-for="(item,index) in result" :key="index">
														<v-list-item-content class="gray">
															<v-list-item-title><strong>{{ item.displayName}}</strong></v-list-item-title>
															<p>Score: {{item.resScore}}</p>
															<p>Baseline: {{item.resBase}}</p>
																<div class="d-flex align-center">
																<div class="mr-4"><strong> {{ item.score}}</strong></div>
																	<v-progress-linear
																		color="primary"
																		height="30"
																		:value="item.score"
																		striped
																	/>
																</div>
															<v-divider/>
														</v-list-item-content>
													</v-list-item>
												</section>
											</v-card-text>
											<v-card-actions>
												<v-spacer/>
												<v-btn depressed color="primary"  @click="resetForm"><v-icon class="mr-2">mdi-recycle-variant</v-icon> RESET </v-btn>
											</v-card-actions>
										</v-card>
									</v-col>
								</v-row>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
<!--
			<pre>
				{{ tId }}
				{{ this.tmodel}}
			</pre> -->

	</Layout>
</template>

<script>
	import axios from 'axios';
	import { mapState, mapActions } from "vuex";
	import store from '@/store'
	const header = {"Token": store.state.authModule.token}
	const configuration = {headers: header}
	export default {
		name: 'TestForm',
		data: () => ({
			targetVariable: '',
			processing: false,
			calculated: false,
			targetVarHint: '',
			valid: false,
			rules: {
				required: value => !!value || 'Required.',
			},
			result: [{
				id: 0,
				score: null,
				displayName: null,
				tables: [{
					importance: null,
					displayName: null
				}]
			}],
			vars: []
		}),
		created(){
			this.getModel(this.tId);
		},
		methods: {
			...mapActions('tmodelModule',['getModel']),
			async calculate(){
				this.calculated = true
				this.processing = true
				let vals = []
				let colsids = []
				this.tmodel.variables.forEach(item => {
					vals.push({'stringValue': item.data})
					colsids.push(item.columnSpecId)
				});

				let me = this;
				let response = await axios.post('tmodel/predict',
					{
						'projectId': me.tmodel.projectId,
						'modelId': me.tmodel.modelId,
						'keyJson': me.tmodel.keyJson,
						'values': vals,
						'columnSpecIds': colsids,

					},configuration)

				me.result = [];
				let index = 1;
				for (const result of response.data) {

					// Get features of top importance
					const featureList = result.tables.tablesModelColumnInfo.map(
						columnInfo => {
							return {
								importance: columnInfo.featureImportance,
								displayName: columnInfo.columnDisplayName,
							};
						}
					);

					// Sort features by their importance, highest importance first
					featureList.sort((a, b) => {
						return b.importance - a.importance;
					});

					me.result.push({
						id: index,
						displayName: result.tables.value.stringValue,
						//score: ( (result.tables.score + result.tables.baselineScore) * 100 ).toFixed(2),
						score: ( (result.tables.score) * 100 ).toFixed(2),
						tables: featureList.slice(0, 10),
						resScore: result.tables.score,
						resBase: result.tables.baselineScore
					});
					index++;
				}

				me.result.sort((a, b) => {
					return b.score - a.score;
				});

				this.processing = false

			},
			resetForm(){
				this.calculated = false
				this.$refs.form.reset()
			},
			setValue(event){
				this.tmodel.variables[event.index-1].data = event.data
			},
			toLowerCase (obj ){
				this.tc = obj.toString().toLowerCase();
			},
		},
		computed: {
			...mapState(['loading']),
			...mapState('tmodelModule',['tmodels','tmodel']),
			tId(){
				return this.$route.params.id
			},
			// currentTitle () {
      //   switch (this.step) {
      //     case 1: return 'Fill data to test'
      //     case 2: return 'Test Result'
      //     default: return 'TEST MODULE FORM'
      //   }
      // },
		}
	}
</script>

<style lang="scss" scoped>

</style>